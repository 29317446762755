import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { size: Number }
  static targets = [ "input", "errorMessage", "nameDisplay" ]
  static classes = [ "errorInput", "errorMessage" ]

  validate() {
    if (this.fileSizeMb() > this.sizeValue) {
      event.stopImmediatePropagation()
      this.inputTarget.classList.add(this.errorInputClass)
      this.errorMessageTarget.classList.add(this.errorMessageClass)
      this.errorMessageTarget.innerHTML = `File should be less than ${this.sizeValue} MB!`
      this.nameDisplayTarget.innerHTML = ""
    } else {
      this.inputTarget.classList.remove(this.errorInputClass)
      this.errorMessageTarget.classList.remove(this.errorMessageClass)
      this.errorMessageTarget.innerHTML = ""
    }
  }

  fileSizeMb() {
    return this.inputTarget.files[0].size / (1024*1024);
  }

}
