import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.observer = this.createObserver()
  }

  createObserver() {
    return new IntersectionObserver(this.onIntersection.bind(this), this.options)
  }

  onIntersection([event]) {
    this.element.classList.toggle('sticky', event.intersectionRatio < 1)
  }

  connect() {
    const marker = this.getStickyMarker()
    this.element.before(marker)
    this.observer.observe(marker)
  }


  getStickyMarker() {
    return document.createElement("div")
  }

  get options() {
    return { rootMargin: this.rootMargin, threshold: [0,1] }
  }

  get rootMargin() {
    return this.data.get("root-margin") || "0px"
  }
}
