/*
 * BackgroundAction allow running background actions.
 *
 * example:
 *
 * <button
 *   data-controller="background-action"
 *   data-action: "background-action#perform",
 *   data-background-action-action-value: "/benefits/1/perform">
 *
 * action & method are optional.
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    action: String
  }

  perform(event) {
    fetch(this.actionValue);
  }
}
