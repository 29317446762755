const toggleClass = (el, cls, condition) => {
    if (Array.isArray(cls)) {
        cls.map((className) => {
            el.classList.toggle(className, condition)
        })
    } else {
        el.classList.toggle(cls, condition)
    }
}

export { toggleClass }
