import { Controller } from "@hotwired/stimulus"

// https://gist.github.com/yunusemredilber/c0598e168ef643fa8e9876b78c447b91
export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.style.resize = 'none';
    this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
    this.inputTarget.style.overflow = 'hidden';
  }

  resize() {
    event.target.style.height = '5px';
    event.target.style.height =  `${event.target.scrollHeight}px`;
  }
}
