import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("scroll", this.onScroll.bind(this))
  }

  onScroll(event) {
    requestAnimationFrame(() => {
      this.rotate()
    })
  }

  rotate() {
    this.element.style.transform = `rotate(${this.rotateDeg})`
  }

  get rotateDeg() {
    return `${window.scrollY * this.speed}deg`
  }

  get speed() {
    return parseFloat(this.data.get("speed") || 1)
  }
}
