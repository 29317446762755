import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]
  static values = {
    consent: String
  }

  connect() {
    window.addEventListener('CookiebotOnAccept', this.onAccept.bind(this))
  }

  onAccept() {
    if (Cookiebot.consent[this.consentValue]) {
      this.render()
    }
  }

  get scripts() {
    return this.element.getElementsByTagName('script')
  }

  render() {
    this.element.innerHTML = this.templateTarget.innerHTML
    for (var i = 0; i < this.scripts.length; i++) {
      var s = document.createElement('script')
      var a = this.scripts[i].attributes
      for (var j = 0; j < a.length; j++) {
        s[a[j].nodeName] = a[j].value
      }
      if (this.scripts[i].innerHTML) {
        s.text = this.scripts[i].innerHTML
      }

      this.scripts[i].parentNode.replaceChild(s, this.scripts[i])
    }
  }
}


