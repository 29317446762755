import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 }
  }

  connect() {
    window.scroll(this.xValue, this.yValue);
  }
}
