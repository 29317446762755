import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "label" ]
  static classes = [ "hidden" ]
  static values = {
    changed: {
      type: Boolean, 
      default: false
    }
  }

  connect() {
    if (this.changedValue) {
      this.showChanged()
    }

    this.textInput.forEach(input => input.addEventListener("keyup", this.toggleTarget.bind(this)))
    this.numberInput.forEach(input => input.addEventListener("keyup", this.toggleTarget.bind(this)))
    this.radioInput.forEach(input => input.addEventListener("click", this.toggleTarget.bind(this)))
    this.checkboxInput.forEach(input => input.addEventListener("click", this.toggleTarget.bind(this)))
    this.rangeInput.forEach(input => input.addEventListener("change", this.toggleTarget.bind(this)))
    this.element.addEventListener("trix-change", this.toggleTarget.bind(this))
    this.selectInput.on("select2:select", this.toggleTarget.bind(this));
  }

  toggleTarget(input) {
    this.changedValue = true
    this.showChanged()
  }

  showChanged() {
    this.labelTarget.classList.remove(this.hiddenClass)
  }

  get textInput() {
    return this.element.querySelectorAll("input[type=text]")
  }

  get numberInput() {
    return this.element.querySelectorAll("input[type=number]")
  }

  get radioInput() {
    return this.element.querySelectorAll("input[type=radio]")
  }

  get checkboxInput() {
    return this.element.querySelectorAll("input[type=checkbox]")
  }

  get rangeInput() {
    return this.element.querySelectorAll("input[type=range]")
  }

  get selectInput() {
    return $("select")
  }
}
