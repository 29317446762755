import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox']

  toggle(event) {
    if( this.behavior === 'unique' ) {
      const currentTarget = event.currentTarget
      this.checkboxTargets.forEach( element => {
        if (element !== currentTarget) {
          element.checked = false
        }
      });
    }
  }

  get behavior() {
    return this.data.get("behavior")
  }
}
