import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="save-disable"
export default class extends Controller {
  static values = {
    selector: String,
    message: String,
  }

  submit(event) {
    if (this.hasSelector()) {
      if (!confirm(this.messageValue)) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    }
  }

  hasSelector() {
    return document.querySelector(this.selectorValue) !== null
  }
}
