import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: String,
    name: String,
  }

  toggle(event) {
    if (event.type == "click") {
      event.preventDefault()
    }
    this.target.forEach(target => target.classList.toggle(this.nameValue))
  }

  get target() {
    return document.querySelectorAll(this.targetValue)
  }
}
