import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]

  connect() {
    this.setInactiveTabHeight();
  }

  setTabsHeight() {
    setTimeout(() => {
      this.setActiveTabHeight();
      this.setInactiveTabHeight();
    }, 0);
  }

  setActiveTabHeight() {
    this.setTabHeight(this.activeTab, ".tab-content");
  }

  setInactiveTabHeight() {
    this.inactiveTabs.forEach(tab => this.setTabHeight(tab, "h3"));
  }

  setTabHeight(tabElement, content) {
    let contentElement = tabElement.querySelector(content);
    if (contentElement) {
      tabElement.style.height = `${tabElement.querySelector(content).offsetHeight}px`;
    }
  }

  get activeTab() {
    return this.tabTargets.find(tab => tab.classList.contains("active"));
  }

  get inactiveTabs() {
    return this.tabTargets.filter(tab => !tab.classList.contains("active"));
  }
}
