import select2 from "select2"
select2(window)

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    width: { type: String, default: "100%" },
    url: String,
    new: Boolean,
    tags: Boolean,
    allowClear: Boolean,
    maximumSelectionLength: Number
  }

  connect() {
    this.ajaxData = this.ajaxData.bind(this)
    this.processResults = this.processResults.bind(this)
    this.setWidthForElement()
    this.select2 = this.initializeSelect2()
    this.observeOnUnselect()
    this.observeOnSelect()
    this.observeOnClear()
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

  observeOnSelect() {
    this.select2.on("select2:select", this.dispatchChangeEvent.bind(this))
  }

  observeOnClear() {
    this.select2.on("select2:clear", this.dispatchChangeEvent.bind(this))
  }

  observeOnUnselect() {
    if (this.removeElementOnUnselect()) {
      return $(this.element).on("select2:unselect", this.onUnselect.bind(this))
    }
  }

  removeElementOnUnselect() {
    return this.element.multiple && this.ajaxOptions
  }

  onUnselect(event) {
    let id = event.params.data.id.replace(/('|")/g, "\\$1")
    return this.element.find(`option[value='${id}']`).remove()
  }

  processResults(data) {
    if (this.newValue && this.query && !this.includeQuery(data.results)) {
      data.results.unshift(this.newResult)
    }
    return data
  }

  get newResult() {
    return {
      text: this.query,
      id: this.query
    };
  }

  setWidthForElement() {
    this.element.style.width = this.widthValue
  }

  initializeSelect2() {
    return $(this.element).select2(this.options)
  }

  dispatchChangeEvent() {
    let event = new Event("change", {
      bubbles: true
    })
    this.element.dispatchEvent(event)
  }

  templateResult(result) {
    return result.label || result.text
  }

  includeQuery(results) {
    let i, len, result
    for (i = 0, len = results.length; i < len; i++) {
      result = results[i]
      if (result.text.toLowerCase() === this.query.toLowerCase()) {
        return true
      }
    }
    return false
  }

  get options() {
    let options = {}
    options.placeholder = this.placeholder
    options.templateResult = this.templateResult
    options.allowClear = this.allowClearValue
    options.theme = "bootstrap-5"

    if (this.tagsValue) {
      options.tags = []
    }

    if (this.ajaxOptions) {
      options.ajax = this.ajaxOptions
    }

    if (this.hasMaximumSelectionLengthValue) {
      options.maximumSelectionLength = this.maximumSelectionLengthValue
    }

    options.dropdownParent = this.element.parentNode
    return options
  }

  get placeholder() {
    return this.element.getAttribute('placeholder')
  }

  get ajaxOptions() {
    if (this.hasUrlValue) {
      return {
        url: this.urlValue,
        dataType: 'json',
        cache: true,
        processResults: this.processResults,
        data: this.ajaxData,
        delay: 10
      }
    }
  }

  ajaxData(params) {
    this.query = params.term
    this.page = params.page
    return {
      query: this.query,
      page: this.page
    }
  }
}
