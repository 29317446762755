import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slider", "output"]
  static values = {
    locale: { default: undefined }
  }

  connect() {
    this.update()
  }

  update(event) {
    this.outputTargets.forEach(this.updateOutput.bind(this))
  }

  updateOutput(output) {
    let multiplier = parseFloat(output.dataset.multiplier || 1)
    let precision = parseInt(output.dataset.precision || 0)
    output.textContent = Number((this.sliderTarget.value * multiplier).toFixed(precision)).toLocaleString(this.element.dataset.rangeLocaleValue)
  }
}
