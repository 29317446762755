import { Controller } from "@hotwired/stimulus"
import { toggleClass } from "../utils/toggle_class"

export default class extends Controller {
  static targets = ['characterCounter', 'input']

  connect() {
    this.updateCounter()
  }

  updateCounter() {
    if(!this.characterCounterTarget) return
    this.characterCounterTarget.innerHTML =
      this.max ? `${this.count}/${this.max}` : this.count
  }

  updateError() {
    if (!this.max) return
    if (this.count > this.max) {
      this.inputTarget.classList.add('is-invalid')
    } else {
      this.inputTarget.classList.remove('is-invalid')
    }
    toggleClass(this.characterCounterTarget, ['invalid-feedback', 'd-inline'], this.count > this.max)
  }

  update() {
    this.updateCounter()
    this.updateError()
  }

  get count() {
    return this.inputTarget.value
        .replace(/\r(?!\n)|\n(?!\r)/g, "\r\n") // Carriage returns are considered 2 characters each when it comes to length
        .replace(/&.+?;/g, " ") // HTML entities are replaced by a single character
        .replace(/<[^>]*>/g, "") // HTML tags are removed
        .length
  }

  get max() {
    return parseInt(this.data.get('max'))
  }
}
