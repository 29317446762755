import { Controller } from "@hotwired/stimulus"
import CheckboxLimiter from "@neubloc/CheckboxLimiter"

export default class extends Controller {
  static values = { limit: Number }

  connect() {
    $(this.element).checkboxlimiter({
      limitcheckbox: this.limitValue
    });
  }
}
