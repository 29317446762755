import { Controller } from "@hotwired/stimulus"
import PlacesAutocomplete from "stimulus-places-autocomplete"

window.initAutocomplete = function () {
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  })

  window.dispatchEvent(event)
  window.initAutocompleteDone = true
}

// Connects to data-controller="location-select"
export default class extends PlacesAutocomplete {
  static targets = [ "inputdata", "name" ]
  static values = {
    types: String
  }

  connect() {
    if (window.initAutocompleteDone) {
      this.initAutocomplete()
    }
    window.addEventListener("google-maps-callback", this.initAutocomplete.bind(this))
  }

  placeChanged() {
    super.placeChanged()
    
    const place = this.autocomplete.getPlace()
    this.inputdataTarget.value = JSON.stringify(place)
    this.nameTarget.value = this.addressTarget.value

    const event = new Event('placeChanged', {
      bubbles: true,
      cancelable: true,
    })

    this.addressTarget.dispatchEvent(event)
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions);
    this.autocomplete.setTypes([this.typesValue]);
    this.autocomplete.addListener("place_changed", this.placeChanged);
  }

  get autocompleteOptions() {
    return {
      fields: ["address_components", "geometry", "place_id"]
    }
  }
}
