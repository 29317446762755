import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = [
    "source"
  ]
  static values = {
    successContent: String,
    successDuration: {
      type: Number,
      default: 2000,
    },
  }

  copy(event) {
    // prevent the default action of the trigger element
    event.preventDefault()

    // copy the content from the sourceTarget to the clipboard
    if (!navigator.clipboard) {
      console.log("Not HTTPS")
      return
    }
    let currentTarget = event.currentTarget
    navigator.clipboard.writeText(this.sourceTarget.value).then(() => this.copied(currentTarget))

  }

  copied(target) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    let tooltip = new bootstrap.Tooltip(target, {trigger: "manual"})
    tooltip.show()

    this.timeout = setTimeout(() => {
      tooltip.hide()
    }, this.successDurationValue)
  }
}
