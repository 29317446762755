import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { amount: Number }

  connect() {
    this.updateProgress();
  }

  amountValueChanged() {
    this.updateProgress();
  }

  updateProgress() {
    this.element.style.width = `${this.amountValue}%`;
  }
}
