import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    this.element.addEventListener('shown.bs.modal', this.onShow.bind(this))
    this.element.addEventListener('hidden.bs.modal', this.onHide.bind(this))
    this.modal = new Modal(this.element)
    this.modal.show()
  }

  disconnect() {
    this.modal.hide()
  }

  onShow() {
    const autofocusInput = this.element.querySelector("[autofocus]")

    if (autofocusInput) {
      autofocusInput.focus()
    }
  }

  onHide() {
    this.element.remove()
  }
}
